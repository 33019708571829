import React from 'react'
// import { useRouteError } from 'react-router-dom'

export const NotFound = () => {

  return (
    <>
    <h1>Page you are looking for doesnt exist</h1>
    {/* {console.log(err)} */}
    </>
  )
}
